<template>
    <div class="content">
        <div class="main-title">
            <div class="main-title__left">
                <div class="h1" v-html="pageTitle"></div>
            </div>

            <div class="main-title__right">
                <span class="i i-info mr4"></span>
                <span class="t">Объекты, по которым мастер<br>должен сдать нам деньги</span>
            </div>
        </div>
        <!-- Здесь пока ничего нет -->
        <nothing-block v-if="activeSecondMenu.name && !requests.length && !isLoad && typeof(lastTabText) === 'undefined'"/>

        <section class="container">
            <requests-list v-if="pageCode" :status="pageCode"/>
        </section>

        <request-popups></request-popups>

    </div>
</template>
<script>
import {mapState} from 'vuex';
import Tooltip from '@/components/Tooltip.vue';
import Popup from '@/components/modal/Popup.vue';
import RequestsNew from '@/components/requests/RequestsNew.vue'
import RequestsWaitContract from '@/components/requests/RequestsWaitContract.vue'
import RequestsVerify from '@/components/requests/RequestsVerify.vue'
import RequestsPreApproved from '@/components/requests/RequestsPreApproved.vue'
import RequestsWaitingInterview from '@/components/requests/RequestsWaitingInterview.vue'
import RequestsRejected from '@/components/requests/RequestsRejected.vue'
import RequestsApproved from '@/components/requests/RequestsApproved.vue'
import RequestsList from "@/components/requests/RequestsList";
import RouteBlock from '@/components/RouteBlock.vue';
import NothingBlock from '@/components/NothingBlock.vue';
import RequestPopups from '@/components/RequestPopups.vue';

export default {
    name: "Buh",
    props: {

    },
    components: {
        RequestsList,
        Tooltip,
        Popup,
        RequestsNew,
        RequestsWaitContract,
        RequestsVerify,
        RequestsPreApproved,
        RequestsWaitingInterview,
        RequestsRejected,
        RequestsApproved,
        RouteBlock,
        NothingBlock,
        RequestPopups,
    },
    data: function(){
        return {

        }
    },
    mounted () {
        //console.log('1111');
    },
    computed: {
        ...mapState({
            itemTopActive: state => state.menus.itemTopActive,
            itemSecondActive: state => state.menus.itemSecondActive,
            menuSecond: state => state.menus.itemsSecond,
            popups: state => state.popups.items,
            requests: state => state.requests.items,
            isLoad: state => state.requests.isLoad,
            user: state => state.auth.user,
            menuList: state => state.menus.itemsSecondBuh,
        }),
        pageCode() {
            return this.activeSecondMenu.id ? 'master'+this.activeSecondMenu.id : '';
        },
        activeSecondMenu() {
            let code = '';
            let item = {};

            if (this.$route) {
                code = this.$route.path;
            }

            if (typeof code !== 'undefined') {
                code = code.split('/')[2];
            }
            console.log(code)
            for(let m in this.menuList){
                if(code == this.menuList[m].id){
                    item = this.menuList[m]
                }
            }

            if (!item.id && this.menuList[0]) {
                item = this.menuList[0];
            }

            return item
        },
        pageTitle() {
            return this.activeSecondMenu.name ? 'Объекты мастера «'+this.activeSecondMenu.name.trim()+'»' : '';
        },
    },
    methods: {
        fetchData () {

        },
        openHowPressBtn(){
            this.$store.dispatch('popups/open', {name: this.popups.howPressBtn.name});
        }
    },
    watch: {
        '$route': 'fetchData'
    },
}
</script>
